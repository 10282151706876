import React, { FC, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { HighFive } from "@components/molecules/HighFive";
import {
  AcceptJobOffer,
  AcceptJobOfferFormOutput,
} from "@components/atoms/AcceptJobOffer";
import { Modal } from "@components/atoms/Modal";
import { JobOffer, AcceptingJobOffer } from "@domain/job-offers";
import { client } from "@integration/next-account-api";

type Props = {
  onClose: (reload: boolean) => void;
  jobOffer?: JobOffer;
};

type ModalState = "accept-job-offer" | "high-five" | "default";

export const AcceptJobOfferContainer: FC<Props> = ({ onClose, jobOffer }) => {
  const [modalState, setState] = useState<ModalState>("accept-job-offer");
  const [acceptJobOffer, { loading }] = useMutation<
    unknown,
    {
      id: string;
      input: AcceptingJobOffer;
    }
  >(
    gql`
      mutation ($id: String!, $input: AcceptJobOfferInput!) {
        acceptJobOffer(id: $id, input: $input)
      }
    `,
    { client },
  );

  const hideModal = () => {
    setState("default");
    onClose(true);
  };

  if (!jobOffer) {
    return <></>;
  }

  const onAcceptJobOffer = async (values: AcceptJobOfferFormOutput) => {
    await acceptJobOffer({
      variables: {
        id: jobOffer?.id,
        input: {
          employmentStartDate: values.employmentStartDate,
        },
      },
    });
    if (!!values.employmentStartDate) {
      setState("high-five");
    } else {
      onClose(true);
    }
  };

  return (
    <>
      {modalState === "accept-job-offer" && (
        <Modal width="600px" visible onCancel={() => onClose(false)}>
          <AcceptJobOffer
            jobOffer={jobOffer}
            loading={loading}
            onAcceptJobOffer={onAcceptJobOffer}
            onClose={() => onClose(false)}
          />
        </Modal>
      )}
      {modalState === "high-five" && (
        <Modal width="600px" visible onCancel={() => onClose(false)}>
          <HighFive
            onClose={() => hideModal()}
            shareLink={"https://www.codelex.io/es-tikko-ieguvu-jaunu-darbu"}
          />
        </Modal>
      )}
    </>
  );
};
