import React from "react";
import EnvelopeIcon from "@public/svg/envelopePerimeter.svg";
import styles from "./index.module.scss";

type Props = {
  onClickVerify: () => void;
};

export const VerifyEmailReminder = ({ onClickVerify }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.verifyEmailWrapper}>
        <EnvelopeIcon className={styles.mailIcon} />
        <span className={styles.description}>
          Nepieciešams apstiprināt e-pasta adresi
        </span>
        <button className={styles.verifyButton} onClick={onClickVerify}>
          Apstiprināt
        </button>
      </div>
    </div>
  );
};
