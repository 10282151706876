import { useQuery, gql } from "@apollo/client";
import { client } from "@integration/next-account-api";

export const useFetchDiscordJoiningUrl = () => {
  return useQuery<{ url?: string }>(
    gql`
      query {
        url: fetchDiscordJoiningUrl
      }
    `,
    { client: client },
  );
};
