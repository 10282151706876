import { useRouter } from "next/router";
import { ApplicationStatusBanner } from "@components/molecules/ApplicationStatusBanner";
import { PageLoading } from "@components/layouts/PageLoading";
import { Content } from "@content/status-banner/types";
import { trpc } from "@domain/account/trpc";

type Props = {
  content: Content;
};

export const ApplicationStatusContainer = ({ content }: Props) => {
  const router = useRouter();
  const { data: application, isLoading } =
    trpc.application.fetchLatestApplication.useQuery();
  if (isLoading) {
    return <PageLoading />;
  }
  const reapplyTime = application?.reapplyWaitingTime || undefined;
  return (
    <ApplicationStatusBanner
      content={content}
      status={application?.status || "PENDING"}
      interviewBookingUrl={application?.interviewBookingUrl}
      reapplyTime={reapplyTime}
      onNavigate={(to) => router.push(to)}
      interview={
        application?.interview
          ? {
              ...application.interview,
              startTime: application.interview.startTime || undefined,
            }
          : undefined
      }
      isConsiderable={application?.isConsiderable}
    />
  );
};
