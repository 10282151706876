import Heart from "@public/svg/heart-greeting.svg";
import styles from "./index.module.scss";

export const FooterPageLayout = () => {
  return (
    <>
      <div className={styles.footerWrapper}>
        <p className={styles.footerText}>
          Ja reģistrācijas vai pierakstīšanās procesā rodas tehniski jautājumi,
          neskaidrības vai problēmas, lūdzu, nekautrējies sazinies, rakstot uz
          info@codelex.io.
        </p>
        <div className={styles.iconWrapper}>
          <Heart />
        </div>
      </div>
    </>
  );
};
