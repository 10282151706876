import { ParsedUrlQuery } from "querystring";
import { setCookie } from "cookies-next";
import { findQueryParam } from "@utils/uri/findQueryParam";
import { cookieAge } from "@integration/cookies";

export const storeMarketingData = ({ query }: { query: ParsedUrlQuery }) => {
  const refHost = findQueryParam({ key: "ref-host", ctx: { query } });
  if (refHost) {
    setCookie("ref_host", refHost, { maxAge: cookieAge({ days: 3 }) });
  }
  const refPatname = findQueryParam({ key: "ref-pathname", ctx: { query } });
  if (refPatname) {
    setCookie("ref_pathname", refPatname, { maxAge: cookieAge({ days: 3 }) });
  }
};
