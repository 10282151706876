import { v4 } from "uuid";

const TOKEN_KEY = "delete_account_token";

export const isTokenMatching = (token: string): boolean => {
  const stored = localStorage.getItem(TOKEN_KEY);
  if (!stored) {
    return false;
  }
  return token === stored;
};

export const createToken = (): string => {
  const token = v4();
  localStorage.setItem(TOKEN_KEY, token);
  return token;
};
