import { gql, useQuery } from "@apollo/client";
import { MembershipStatus } from "@codelex/github-api";
import { client } from "@integration/next-account-api";

export const useFetchGithubMembershipStatus = ({
  pollInterval,
}: {
  pollInterval: number;
}) => {
  return useQuery<{ membershipStatus: MembershipStatus }>(
    gql`
      query {
        membershipStatus: fetchGithubMembershipStatus
      }
    `,
    {
      client,
      pollInterval,
    },
  );
};
