import React, { FC } from "react";
import { message } from "antd";
import { Modal } from "@components/atoms/Modal";
import {
  AddComment,
  AddCommentFormOutput,
} from "@components/molecules/AddComment";
import { trpc } from "@domain/account/trpc";

type Props = {
  entryId: string;
  onClose: (reload: boolean) => void;
};

export const AddCommentContainer: FC<Props> = ({ entryId, onClose }) => {
  const { mutateAsync: addCommentToReferralEntry, isLoading: loading } =
    trpc.referrals.addCommentToReferralEntry.useMutation();

  const onAddComment = async (values: AddCommentFormOutput) => {
    await addCommentToReferralEntry({
      entryId,
      content: values.content,
    });
    message.info("Komentārs ir veiksmīgi pievienots! 🎉");
    onClose(true);
  };

  return (
    <Modal width="600px" visible onCancel={() => onClose(false)}>
      <AddComment
        onAddComment={onAddComment}
        onClose={() => onClose(false)}
        loading={loading}
      />
    </Modal>
  );
};
