import React, { FC } from "react";
import { message } from "antd";
import { gql, useMutation } from "@apollo/client";
import { Modal } from "@components/atoms/Modal";
import {
  AddReferralManually,
  AddReferralFormOutput,
} from "@components/molecules/AddReferralManually";
import { AddReferralManuallyVariables } from "@domain/referrals/types";
import { client } from "@integration/next-account-api";

type Props = {
  onClose: (reload: boolean) => void;
};

export const AddReferralManuallyContainer: FC<Props> = ({ onClose }) => {
  const [addReferralEntryManually, { loading }] = useMutation<
    unknown,
    {
      input: AddReferralManuallyVariables;
    }
  >(
    gql`
      mutation ($input: AddManualReferralEntryInput!) {
        addReferralEntryManually(input: $input)
      }
    `,
    { client },
  );

  const onAddReferral = async (values: AddReferralFormOutput) => {
    await addReferralEntryManually({
      variables: {
        input: {
          company: values.company,
          comments: values.comment,
        },
      },
      onCompleted: () => {
        message.info("Ieraksts pievienots! 🎉");
        onClose(true);
      },
    });
  };

  return (
    <Modal width="600px" visible onCancel={() => onClose(false)}>
      <AddReferralManually
        onAddReferral={onAddReferral}
        onClose={() => onClose(false)}
        loading={loading}
      />
    </Modal>
  );
};
