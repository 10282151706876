import { useRouter } from "next/router";
import { AccountAware } from "@components/layouts/types";
import { menuItems } from "@domain/access/menu";
import { NavMenuVertical } from "@components/molecules/NavMenuVertical";

export type Props = AccountAware;

export const PageNavMenuContainer = ({ account }: Props) => {
  const router = useRouter();
  const { pathname } = router;

  const { entries } = menuItems({
    ...account,
    accountCreatedAt: new Date(account.createdAt),
    application: account.application || undefined,
    showFeatures: false,
  });
  return (
    <NavMenuVertical
      menuItems={entries}
      pathName={pathname}
      gender={account.gender}
      accountId={account.id}
      isRegisteredToSheCanDoIt={account.isRegisteredToSheCanDoIt}
    />
  );
};
