import { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { useRouter } from "next/router";
import { lv } from "date-fns/locale";
import setDefaultOptions from "date-fns/setDefaultOptions/index.js";
import { posthog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { NextPageWithLayout } from "@components/layouts/types";
import { trackPageView, identify } from "@integration/analytics";
import { client } from "@integration/next-account-api/apollo";
import { HeadComponent } from "@components/atoms/HeadComponent";
import { AccountContextProvider } from "@containers/customer/AccountContext";
import { MarketingScripts } from "@containers/customer";
import { VerifyEmailContextProvider } from "@containers/customer/VerifyEmailContainer";
import { ErrorBoundary } from "@integration/errors/bugsnag/csr";
import { storeMarketingData } from "@integration/marketing/csr";
import { useFeatures } from "@domain/features";
import { getAccountId } from "@domain/account/cookies/csr";
import { clientSideEnvVariable } from "src/client-side-env";
import { trpc } from "@domain/account/trpc";
import type { AppProps } from "next/app";

const Head = <HeadComponent description="" image="" url="" title="" />;

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const Container = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter();
  const { pathname, isReady, query } = router;
  useEffect(() => {
    setDefaultOptions({ locale: lv });
    const POSTHOG_KEY = clientSideEnvVariable("POSTHOG_KEY");
    const POSTHOG_HOST = clientSideEnvVariable("POSTHOG_HOST");
    if (POSTHOG_KEY && POSTHOG_HOST) {
      posthog.init(POSTHOG_KEY, {
        api_host: POSTHOG_HOST,
        loaded: (posthog) => {
          if (process.env.NODE_ENV === "development") {
            posthog.debug();
          }
        },
      });
    }
  }, []);
  useFeatures();
  useEffect(() => {
    trackPageView();
    const accountId = getAccountId();
    if (accountId) {
      identify({ userId: accountId });
    }
  }, [pathname, isReady]);
  useEffect(() => storeMarketingData({ query }), [query]);

  const getLayout = Component.getLayout ?? (({ page }) => <>{page}</>);
  const Providers: React.FC<React.PropsWithChildren> = ({ children }) => {
    if (Component.skipProviders) {
      return (
        <>
          {Head}
          {children}
        </>
      );
    }
    return (
      <ApolloProvider client={client}>
        <AccountContextProvider>
          <VerifyEmailContextProvider>
            {Head}
            {children}
          </VerifyEmailContextProvider>
        </AccountContextProvider>
      </ApolloProvider>
    );
  };
  return (
    <ErrorBoundary>
      <PostHogProvider client={posthog}>
        <MarketingScripts />
        <Providers>
          {getLayout({
            page: (
              <>
                {Head}
                <Component {...pageProps} />
              </>
            ),
            router,
            props: pageProps,
          })}
        </Providers>
      </PostHogProvider>
    </ErrorBoundary>
  );
};

const Wrapped = trpc.withTRPC(Container);

export const PublicAppContainer: React.FC<AppPropsWithLayout> = (props) => (
  <Wrapped {...(props as unknown as (typeof Wrapped)["prototype"])} />
);
