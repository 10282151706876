import React from "react";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import Link from "next/link";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

export interface Props {
  accountId: string;
}

export const SheCanDoITBanner = ({ accountId }: Props) => {
  const router = useRouter();
  const url = `https://www.shecandoit.lv/?utm_source=codelex&utm_medium=affiliate&utm_campaign=join_shecandoit&account_id=${accountId}`;
  return (
    <Link href={url} className={styles.container}>
      <Image
        src={"/svg/shecandoit-background.svg"}
        alt="background"
        height={494}
        width={154}
        layout="fill"
        className={styles.background}
      />
      <div className={styles.logo}>
        <Image
          src={"/svg/shecandoit-logo.svg"}
          alt={"shecandoit logo"}
          height={24}
          width={140}
        />
      </div>
      <h4 className={styles.title}>
        Attīsties tehnoloģiju jomā ar spēcīgas kopienas atbalstu
      </h4>
      <div className={styles.logoWrapper}>
        <Image
          src={"/svg/shecandoit.svg"}
          alt={"shecandoit"}
          height={202}
          width={199}
        />
      </div>
      <p className={styles.text}>
        Pievienojies, izaicini sevi un audz kopā ar SheCanDoIT!
      </p>
      <div className={styles.buttonWrapper}>
        <Button type="dashed" onClick={() => router.push(url)}>
          Uzzināt vairāk
        </Button>
      </div>
    </Link>
  );
};
