import React, { FC, useState } from "react";
import { message } from "antd";
import {
  AddJobOffer,
  AddJobOfferFormOutput,
} from "@components/molecules/AddJobOffer";
import { HighFive } from "@components/molecules/HighFive";
import { Modal } from "@components/atoms/Modal";
import { JobOffer } from "@domain/job-offers";
import { trpc } from "@domain/account/trpc";

type Props = {
  onClose: (reload: boolean) => void;
  jobOffer?: JobOffer;
};

type ModalState = "edit-job-offer" | "high-five" | "default";

export const EditJobOfferContainer: FC<Props> = ({ onClose, jobOffer }) => {
  const [modalState, setState] = useState<ModalState>("edit-job-offer");
  const { mutateAsync: updateJobOffer, isLoading } =
    trpc.jobOffer.updateJobOffer.useMutation({
      onSuccess: (data) => {
        message.info("Ieraksts labots! 🎉");
        if (!!data.employmentStartDate) {
          setState("high-five");
        } else {
          onClose(true);
        }
      },
    });

  const hideModal = () => {
    setState("default");
    onClose(true);
  };

  if (!jobOffer) {
    return <></>;
  }

  const onAddJobOffer = (values: AddJobOfferFormOutput) => {
    updateJobOffer({
      id: jobOffer?.id,
      company: values.companyName,
      salary: Number(values.salary),
      status: values.status,
      email: values.companyEmail || null,
      phoneNumber: values.phoneNumber || null,
      employmentStartDate: values.employmentStartDate || null,
    });
  };
  return (
    <>
      {modalState === "edit-job-offer" && (
        <Modal width="600px" visible onCancel={() => onClose(false)}>
          <AddJobOffer
            jobOffer={jobOffer}
            loading={isLoading}
            onAddJobOffer={onAddJobOffer}
            onClose={() => onClose(false)}
          />
        </Modal>
      )}

      {modalState === "high-five" && (
        <Modal width="600px" visible onCancel={() => onClose(false)}>
          <HighFive
            onClose={() => hideModal()}
            shareLink={"https://www.codelex.io/es-tikko-ieguvu-jaunu-darbu"}
          />
        </Modal>
      )}
    </>
  );
};
