import { useRouter } from "next/router";
import Head from "next/head";
import { NextPageWithLayout } from "@components/layouts/types";
import { ErrorBoundary } from "@integration/errors/bugsnag/csr";
import { trpc } from "@domain/admin/trpc";
import type { AppProps } from "next/app";

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const Container = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter();
  const getLayout = Component.getLayout ?? (({ page }) => <>{page}</>);
  return (
    <ErrorBoundary>
      <Head>
        <title>🧑‍💻 Codelex</title>
      </Head>
      {getLayout({
        page: <Component {...pageProps} />,
        router,
        props: pageProps,
      })}
    </ErrorBoundary>
  );
};

const Wrapped = trpc.withTRPC(Container);

export const AdminAppContainer: React.FC<AppPropsWithLayout> = (props) => (
  <Wrapped {...(props as unknown as (typeof Wrapped)["prototype"])} />
);
