import { useMutation, gql } from "@apollo/client";
import { client } from "@integration/next-account-api";

export const useSendGithubInvite = () => {
  return useMutation<unknown, unknown>(
    gql`
      mutation {
        sendGithubInvite
      }
    `,
    {
      client: client,
    },
  );
};
