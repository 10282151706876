import { Modal } from "@components/atoms/Modal";
import { ModalContent } from "./ModalContent";

export type EmailUpdateStatus =
  | "enter-email"
  | "loading"
  | "failed"
  | "verification-email-sent";

export type Props = {
  visible: boolean;
  close: () => void;
  onSubmit: ({ email }: { email: string }) => void;
  status: EmailUpdateStatus;
  onEmailNotReceived: () => void;
};

export const UpdateEmail = ({
  visible,
  close,
  onSubmit,
  status,
  onEmailNotReceived,
}: Props) => {
  return (
    <Modal width="600px" visible={visible} onCancel={close}>
      {visible && (
        <ModalContent
          close={close}
          onSubmit={onSubmit}
          status={status}
          onEmailNotReceived={onEmailNotReceived}
        />
      )}
    </Modal>
  );
};
