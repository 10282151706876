import { Tooltip } from "antd";
import { AuthButton } from "src/components/atoms/AuthButton";
import { useFetchDiscordJoiningUrl } from "@domain/discord/csr";

interface Props {
  emailVerified: boolean;
  onClick: () => void;
}

export const JoinDiscord = ({ emailVerified, onClick }: Props) => {
  const { data, loading } = useFetchDiscordJoiningUrl();
  return (
    <Tooltip
      title={
        !loading && !data?.url
          ? `Pievienoties kopējam čatam varēsi pēc ${
              emailVerified
                ? "e-pasta apstiprināšanas"
                : "mājasdarba iesniegšanas"
            }`
          : undefined
      }
      placement="left"
    >
      <span>
        <AuthButton
          onClickOpenModal={onClick}
          title="Discord"
          svgAlt="discord image"
          svgHeight={30}
          svgWidth={30}
          svgSource="/svg/discord.svg"
          target="_blank"
          disabled={!data?.url || loading}
          href={data?.url || undefined}
        />
      </span>
    </Tooltip>
  );
};
