import { gql, useQuery } from "@apollo/client";
import { client } from "@integration/next-account-api";
import { shouldSkipRequests } from "@integration/account-api/page-request-policy";
import { ApplicationStatus } from "@domain/applications/common";

interface Account {
  id: string;
  email?: string;
  leadSourcePresent: boolean;
  personalInformationPresent: boolean;
  assignmentCompleted: boolean;
  anyEventJoined: boolean;
  applicationRequired: boolean;
  emailVerified: boolean;
  application?: {
    status: ApplicationStatus;
  };
  roles: string[];
}

export const useFetchAccountData = () => {
  return useQuery<{ account: Account }>(
    gql`
      query {
        account: fetchAccount {
          id
          email
          leadSourcePresent
          personalInformationPresent
          assignmentCompleted
          anyEventJoined
          applicationRequired
          emailVerified
          application: latestApplication {
            status
          }
        }
      }
    `,
    {
      client,
      pollInterval: 30000,
      skip: shouldSkipRequests(),
    },
  );
};
