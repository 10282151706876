import { AccountRole } from "@domain/roles/types";
import { clientSideEnvVariable } from "src/client-side-env";
import { fetchAvailableStaticCourses } from "@domain/static-courses/config";
import { ApplicationStatus } from "@domain/applications/common";
import { Menu, MenuEntry } from "./types";

const dev = clientSideEnvVariable("DEV_ENV") === "true";

export type Args = {
  showFeatures: boolean;
  roles: AccountRole[];
  assignmentCompleted: boolean;
  applicationAvailable: boolean;
  applicationRequired: boolean;
  application?: { status: ApplicationStatus };
  accountCreatedAt: Date;
  hasJoinedIntensiveCourse?: boolean;
  hasJoinedNext?: boolean;
  paymentScheduleAvailable: boolean;
  hasAccessToProgrammingBasics?: boolean;
  hideContent: boolean;
};

export const isRolePresent = ({
  roles,
  role,
}: {
  roles: AccountRole[];
  role: AccountRole;
}): boolean => {
  return roles.includes(role);
};

const showReferralProgram = ({ roles }: Args): boolean => {
  return (
    isRolePresent({ roles, role: "referral-program-candidate" }) ||
    isRolePresent({ roles, role: "referrer" }) ||
    isRolePresent({ roles, role: "employed-developer" })
  );
};

const showJobOfferProgram = ({ roles }: Args): boolean => {
  return isRolePresent({ roles, role: "job-seeker" });
};

export const menuItems = (args: Args): Menu => {
  const {
    showFeatures,
    roles,
    assignmentCompleted,
    applicationAvailable,
    applicationRequired,
    application,
    accountCreatedAt,
    hasJoinedNext,
    hasJoinedIntensiveCourse,
    paymentScheduleAvailable,
    hasAccessToProgrammingBasics,
    hideContent,
  } = args;
  const courses = fetchAvailableStaticCourses({
    now: new Date(),
    accountCreatedAt,
    hasAccessToProgrammingBasics,
  });
  if (showReferralProgram(args)) {
    return {
      index: "employed-developer",
      entries: [
        {
          key: "employed-developer",
          title: "Sākums",
          path: "/employed-developer",
        },
        {
          key: "payments",
          title: "Rēķini",
          path: "/payments",
          ...(paymentScheduleAvailable
            ? {
                subTitles: [
                  {
                    title: "Maksājumu grafiks",
                    path: "/payments/payment-schedule",
                  },
                ],
              }
            : {}),
        },
        {
          key: "employment-check",
          title: "Darba statuss",
          path: "/employment-check",
        },
        {
          key: "referrals",
          title: "Piesaistes Programma",
          path: "/referrals",
          subTitles: roles.includes("referrer")
            ? [
                {
                  title: "Kopsavilkums",
                  path: "/referrals/dashboard",
                },
                {
                  title: "Statistika",
                  path: "/referrals/statistics",
                },

                {
                  title: "Sagataves",
                  path: "/referrals/templates",
                },
                {
                  title: "Pieejamie līdzekļi",
                  path: "/referrals/withdraw",
                },
              ]
            : undefined,
        },
      ],
    };
  }
  const intensiveCourseCandidate = isRolePresent({
    roles,
    role: "intensive-course-candidate",
  });
  const preIntensiveCourseAttendee = isRolePresent({
    roles,
    role: "pre-intensive-course-attendee",
  });

  const entries: MenuEntry[] = [];
  if (intensiveCourseCandidate) {
    if (!assignmentCompleted) {
      entries.push({
        key: "start",
        title: "Sākums",
        path: "/start",
      });
    }
    entries.push({
      key: "introductory-course",
      title: "Ievadkurss",
      path: "/introductory-course",
    });
    if (assignmentCompleted) {
      entries.push({
        key: "start",
        title: "Mājasdarbs",
        path: "/start",
      });
    }
  }
  if (preIntensiveCourseAttendee && !hideContent) {
    entries.push({
      key: "intensive-course",
      title: "Sagatavošanās Materiāli",
      path: "/intensive-course",
    });
  }
  if (
    (application || applicationAvailable || applicationRequired) &&
    !hasJoinedIntensiveCourse &&
    !hideContent
  ) {
    entries.push({
      key: "activities",
      title: "Sagatavošanās nodarbībai",
      path: "/activities",
      subTitles: [
        {
          title: "Pieteikums",
          path: "/activities/application",
        },
      ],
    });
  }
  if (application?.status === "APPROVED" && !hideContent) {
    entries.push({
      key: "agreement",
      title: "Līgums",
      path: "/agreement",
    });
  }
  if (showJobOfferProgram(args)) {
    if (roles.includes("job-seeker")) {
      entries.push({
        key: "job-seeker-diary",
        title: "Vakanču dienasgrāmata",
        path: "/job-seeker-diary",
      });
    }
    entries.push({
      key: "job-offers",
      title: "Darba piedāvājumi",
      path: "/job-offers",
    });
  }
  if (assignmentCompleted && !intensiveCourseCandidate) {
    entries.push({
      key: "introductory-course",
      title: "Ievadkurss",
      path: "/introductory-course",
    });
  }
  if (assignmentCompleted) {
    entries.push({
      key: "previous-lessons",
      title: "Nodarbību Ieraksti",
      path: "/previous-lessons",
    });
  }
  entries.push({
    key: "courses",
    title: "Kursi",
    path: "/courses",
    subTitles: courses
      .map((item) => {
        if (item.key == "git-and-github") {
          if (!hasJoinedNext || !hasJoinedIntensiveCourse) {
            return null;
          }
        }
        if (item.key == "next") {
          return {
            title: item.navTitle,
            path: `/${item.key}`,
          };
        }
        return {
          title: item.navTitle,
          path: `/courses/${item.key}`,
        };
      })
      .filter((item) => item !== null) as { title: string; path: string }[],
  });
  if (dev && showFeatures) {
    {
      entries.push({
        key: "courses/programming-basics-with-ts",
        title: "TypeScript kurss",
        path: "/courses/programming-basics-with-ts",
      });
    }
  }
  if (dev && showFeatures) {
    entries.push({
      key: "dev/features",
      title: "Testēšana",
      path: "/dev/features",
    });
  }
  return {
    index: entries[0].key,
    entries,
  };
};

export const indexPagePath = (args: Args): string => {
  const { index, entries } = menuItems(args);
  const entry = entries.find((it) => it.key === index);
  if (!entry) {
    return "/start";
  }
  return entry.path;
};

export const entriesFilterForSDLLayout = (entries: MenuEntry[]) => {
  return entries.filter((entry) => !entry.title.toLowerCase().includes("next"));
};
