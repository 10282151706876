import { useRouter } from "next/router";
import { Profile } from "src/components/atoms/Profile";
import { UpcomingLessonBadge } from "src/components/atoms/UpcomingLessonBadge";
import { useJoinCodingEvent } from "@containers/customer/JoinCodingEventContext";
import { CodingEvent } from "@components/layouts/types";
import styles from "./index.module.scss";

export type Props = {
  event: CodingEvent | undefined;
  dateOfStart?: string;
  showUpcommingLesson: boolean;
  hideContent: boolean;
};

export const PageUpperHeader = ({
  event,
  dateOfStart,
  showUpcommingLesson,
  hideContent,
}: Props) => {
  const router = useRouter();
  const { showJoinCodingEventModal } = useJoinCodingEvent();
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        {!showUpcommingLesson || !dateOfStart || hideContent ? (
          <div className={styles.hallowBadge}>&nbsp;</div>
        ) : (
          <UpcomingLessonBadge
            onClick={
              event?.available
                ? showJoinCodingEventModal
                : () => router.push("/introductory-course")
            }
            upcomingLessonTitle="Nākamās nodarbības sākums"
            date={dateOfStart}
          />
        )}

        <div className={styles.userProfileWrapper}>
          <div>
            <Profile />
          </div>
        </div>
      </div>
    </div>
  );
};
