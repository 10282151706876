import Link from "next/link";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import React from "react";
import { HeaderLogo } from "@components/atoms";
import { SheCanDoITBanner } from "@components/atoms/SheCanDoITBanner";
import styles from "./index.module.scss";

export interface Props {
  menuItems: {
    title: string;
    path: string;
    subTitles?: { title: string; path: string }[];
  }[];
  pathName: string;
  gender: null | string;
  accountId: string;
  isRegisteredToSheCanDoIt: boolean;
}

export const NavMenuVertical = ({
  menuItems,
  pathName,
  gender,
  accountId,
  isRegisteredToSheCanDoIt,
}: Props) => {
  const currentPath = usePathname();
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <HeaderLogo link="/" />
        </div>
        {menuItems.map((item, i) => {
          return (
            <div
              key={i}
              className={classNames(styles.link, {
                [styles.linkWithSublinks]:
                  item.subTitles &&
                  (pathName === item.path ||
                    `/${pathName.split("/")[1]}` === item.path),
                [styles.activelink]:
                  pathName.trim() === item.path.trim() ||
                  (!(pathName === item.path) &&
                    `/${pathName.split("/")[1]}` === item.path &&
                    !item.subTitles),
                [styles.mainLinkActive]:
                  item.subTitles &&
                  (item.subTitles.some(
                    (subTitle) => currentPath === subTitle.path,
                  ) ||
                    item.subTitles.some(
                      (subTitle) =>
                        `/${currentPath.split("/").slice(1, 3).join("/")}` ===
                        subTitle.path,
                    )),
              })}
            >
              <div>
                <Link href={item.path}>{item.title}</Link>
              </div>
              {(pathName === item.path ||
                `/${pathName.split("/")[1]}` === item.path) && (
                <>
                  {item.subTitles &&
                    item.subTitles.map(
                      (
                        subItem: { title: string; path: string },
                        index: number,
                      ) => (
                        <div
                          key={index}
                          className={classNames(styles.subLinks, {
                            [styles.subLinkActive]:
                              currentPath === subItem.path ||
                              `/${currentPath
                                .split("/")
                                .slice(1, 3)
                                .join("/")}` === subItem.path,
                          })}
                        >
                          <Link href={subItem.path}>{subItem.title}</Link>
                        </div>
                      ),
                    )}
                </>
              )}
            </div>
          );
        })}
        <div className={styles.banner}>
          {gender === "FEMALE" && !isRegisteredToSheCanDoIt && (
            <SheCanDoITBanner accountId={accountId} />
          )}
        </div>
      </div>
    </>
  );
};
