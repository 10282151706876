import React, { FC, useState } from "react";
import { message } from "antd";
import {
  AddJobOffer,
  AddJobOfferFormOutput,
} from "@components/molecules/AddJobOffer";
import { HighFive } from "@components/molecules/HighFive";
import { Modal } from "@components/atoms";
import { JobApplicationUpsert } from "@domain/account/job-applications/types";
import { trpc } from "@domain/account/trpc";

type Props = {
  onClose: ({
    reload,
    success,
    formData,
  }: {
    reload: boolean;
    success: boolean;
    formData?: JobApplicationUpsert;
  }) => void;
  formData?: JobApplicationUpsert;
};

type ModalState = "add-job-offer" | "high-five" | "default";

export const AddJobOfferContainer: FC<Props> = ({ onClose, formData }) => {
  const [modalState, setState] = useState<ModalState>("add-job-offer");
  const { mutateAsync: addJobOfferDetails, isLoading } =
    trpc.jobOffer.addJobOfferDetails.useMutation({
      onSuccess: (data) => {
        message.info("Ieraksts pievienots! 🎉");
        if (!!data.employmentStartDate) {
          setState("high-five");
        } else {
          onClose({ reload: true, success: true, formData });
        }
      },
    });

  const hideModal = () => {
    setState("default");
    onClose({ reload: true, success: true, formData });
  };

  const onAddJobOffer = (values: AddJobOfferFormOutput) => {
    addJobOfferDetails({
      company: values.companyName,
      salary: Number(values.salary),
      status: values.status,
      email: values.companyEmail || null,
      phoneNumber: values.phoneNumber || null,
      employmentStartDate: values.employmentStartDate || null,
    });
  };
  return (
    <>
      {modalState === "add-job-offer" && (
        <Modal
          width="600px"
          visible
          onCancel={() => onClose({ reload: false, success: false })}
        >
          <AddJobOffer
            loading={isLoading}
            formData={formData}
            onAddJobOffer={onAddJobOffer}
            onClose={() => onClose({ reload: false, success: false })}
          />
        </Modal>
      )}

      {modalState === "high-five" && (
        <Modal
          width="600px"
          visible
          onCancel={() => onClose({ reload: false, success: true, formData })}
        >
          <HighFive
            onClose={() => hideModal()}
            shareLink={"https://www.codelex.io/es-tikko-ieguvu-jaunu-darbu"}
          />
        </Modal>
      )}
    </>
  );
};
