import Script from "next/script";
import { clientSideEnvVariable } from "src/client-side-env";

export const MarketingScripts = () => {
  const GA_TRACKING_ID = clientSideEnvVariable("GA_TRACKING_ID");
  const FB_PIXEL_IDS = clientSideEnvVariable("FB_PIXEL_IDS");
  const CUSTOMER_IO_SITE_ID = clientSideEnvVariable("CUSTOMER_IO_SITE_ID");
  return (
    <>
      {GA_TRACKING_ID ? (
        <>
          <Script
            id="load-google-tag-manager"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script id="init-google-tag-manager" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}');
            `}
          </Script>
        </>
      ) : undefined}
      {FB_PIXEL_IDS ? (
        <Script id="fb-pixel" strategy="afterInteractive">
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            ${FB_PIXEL_IDS.split(";")
              .map((id) => `fbq('init', '${id}');`)
              .join("\n")}
            fbq('track', 'PageView');
          `}
        </Script>
      ) : undefined}

      {CUSTOMER_IO_SITE_ID ? (
        <Script id="customer_io" strategy="afterInteractive">
          {`var _cio = _cio || [];
            (function() {
                var a,b,c;a=function(f){return function(){_cio.push([f].
                concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
                "sidentify","track","page","on","off"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
                var t = document.createElement('script'),
                    s = document.getElementsByTagName('script')[0];
                t.async = true;
                t.id    = 'cio-tracker';
                t.setAttribute('data-site-id', '${CUSTOMER_IO_SITE_ID}');
                t.setAttribute('data-use-array-params', 'true');
                t.src = 'https://assets.customer.io/assets/track-eu.js';
                s.parentNode.insertBefore(t, s);
            })();`}
        </Script>
      ) : undefined}
    </>
  );
};
