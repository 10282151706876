import { Popconfirm } from "antd";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import CopyIcon from "@public/svg/copy-flat.svg";
import Bin from "@public/svg/bin-with-two-stripes.svg";
import { copyToClipboard } from "@integration/clipboard";
import { AccountExternalConnection } from "@domain/account/external-connections/types";
import { createToken } from "@domain/account/delete";
import { AuthConnection } from "@domain/account/auth-connections/types";
import { asNextAuthKey } from "@domain/account/auth-connections/csr";
import { AuthConnectionCard } from "@components/molecules/AuthConnectionCard";
import { AccountEmailCard } from "@components/molecules/AccountEmailCard";
import { ExternalConnectionsCard } from "@components/molecules/ExternalConnectionsCard";
import { useVerifyEmailContext } from "@containers/customer/VerifyEmailContainer";
import { useAccountContext } from "@containers/customer/AccountContext";
import { SignOutButton } from "@components/molecules";
import styles from "./index.module.scss";

interface Props {
  id: string;
  email?: string;
  authConnections: AuthConnection[];
  externalConnections: AccountExternalConnection[];
  allowedToDelete: boolean;
}

export const MyAccountContainer = ({
  id,
  email,
  authConnections,
  externalConnections,
  allowedToDelete,
}: Props) => {
  const router = useRouter();
  const { onVerifyEmailClick } = useVerifyEmailContext();
  const { signOut } = useAccountContext();
  const adjustDiscordUrl = ({ url }: { url: string }): string => {
    return window.location.origin + url.replace("/public-api", "/api/public");
  };
  const onDelete = () => {
    router.push(`/my-account/delete?token=${createToken()}`);
  };
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>Uzstādījumi</h1>
        <>
          <div className={styles.actionWrapper}>
            <div
              onClick={() => copyToClipboard({ content: id })}
              className={styles.clientIdWrapper}
            >
              <span className={styles.clientId}>#{id}</span>
              <CopyIcon className={styles.copyIcon} />
            </div>
            <div className={styles.signOut}>
              <SignOutButton onSignOut={signOut} />
            </div>
          </div>
          <div className={styles.cardsWrapper}>
            <AccountEmailCard
              email={email}
              onChangeEmail={email ? onVerifyEmailClick : undefined}
              onAddEmail={!email ? onVerifyEmailClick : undefined}
            />
            <AuthConnectionCard
              authConnections={authConnections.map((it) => ({
                connection: it,
                onClick: () => {
                  const provider = asNextAuthKey({ connection: it.name });
                  signIn(provider);
                },
              }))}
            />
            <ExternalConnectionsCard
              externalConnections={externalConnections}
              onClick={(it) => {
                if (it.name === "discord" && email && it.details?.joiningUrl) {
                  const url = it.details.joiningUrl;
                  window.open(adjustDiscordUrl({ url }), "_blank");
                }
              }}
            />
          </div>
          {allowedToDelete && (
            <Popconfirm
              title="Vai tu tiešām vēlies dzēst savu kontu?"
              onConfirm={onDelete}
              okText="Jā, tiešām"
              cancelText="Nē"
              placement="right"
            >
              <div className={styles.deleteWrapper}>
                <Bin className={styles.binIcon} />
                <span className={styles.deleteDesc}>Dzēst kontu</span>
              </div>
            </Popconfirm>
          )}
        </>
      </div>
    </>
  );
};
