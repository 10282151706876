import { Row, Col, Spin } from "antd";
import styles from "./index.module.scss";

export const PageLoading = () => {
  return (
    <div className={styles.wrapper}>
      <Row style={{ marginTop: 16 }}>
        <Col xs={24} md={{ span: 16, offset: 4 }} lg={{ span: 8, offset: 8 }}>
          <Spin />
        </Col>
      </Row>
    </div>
  );
};
