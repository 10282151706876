import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import Link from "next/link";
import { UserProfile } from "src/components/atoms/UserProfile";
import { SignOutButton } from "src/components/molecules/SignOutButton";
import styles from "./index.module.scss";

export type Props = {
  items: {
    title: string;
    path: string;
    subTitles?: { title: string; path: string }[];
  }[];
  open: boolean;
  handleMenu: () => void;
  burgerRef: React.MutableRefObject<HTMLDivElement>;
  onSignOut: () => void;
  userName?: string;
  emailVerified: boolean;
  upperHeader?: boolean;
};

export const BurgerMenuNew: React.FC<Props> = ({
  items,
  open,
  burgerRef,
  handleMenu,
  onSignOut,
  userName,
  emailVerified,
  upperHeader = true,
}) => {
  const [activeItemIndex, setActiveItem] = useState<number | null>(null);
  const handleItemClick = (index: number) => {
    setActiveItem(index === activeItemIndex ? null : index);
  };

  useEffect(() => {
    open ? disableBodyScroll(burgerRef.current) : clearAllBodyScrollLocks();
  }, [open, burgerRef]);

  return (
    <div
      data-testid="burger-menu-item-list"
      className={classNames(styles.wrapper, {
        [styles.open]: open,
        [styles.emailVerified]: !emailVerified,
        [styles.upperHeader]: !upperHeader,
      })}
    >
      {items.map(({ title, path, subTitles }, index) => {
        const isActiveItem = activeItemIndex === index;
        return (
          <React.Fragment key={index}>
            <Link
              href={path}
              className={classNames(styles.menuItem, {
                [styles.active]: isActiveItem,
              })}
              onClick={() => {
                handleItemClick(index);
                handleMenu();
              }}
            >
              {title}
            </Link>
            {subTitles &&
              subTitles.map(
                (subItem: { title: string; path: string }, index) => (
                  <Link
                    key={index}
                    href={subItem.path}
                    className={styles.menuSubTitles}
                  >
                    {subItem.title}
                  </Link>
                ),
              )}
          </React.Fragment>
        );
      })}

      <div className={styles.signOut}>
        <SignOutButton onSignOut={onSignOut} />
      </div>

      <div className={styles.userProfile}>
        <UserProfile email={userName} />
      </div>
    </div>
  );
};
