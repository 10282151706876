import { GetServerSidePropsContext, NextApiRequest } from "next";

type Context =
  | Pick<GetServerSidePropsContext, "query">
  | Pick<NextApiRequest, "query">;

export const findQueryParam = ({
  ctx,
  key,
}: {
  ctx: Context;
  key: string;
}): string | undefined => {
  const param = ctx.query[key];
  if (!param || param.length === 0) {
    return undefined;
  }
  if (typeof param === "string") {
    return param;
  }
  return param[0];
};
