import { AuthConnectionType } from "../types";

export const asNextAuthKey = ({
  connection,
}: {
  connection: AuthConnectionType;
}) => {
  switch (connection) {
    case "email":
      return "credentials";
    case "facebook":
      return "facebook";
    case "google":
      return "google";
    case "github":
      return "github";
  }
};
