import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Modal } from "@components/atoms/Modal";
import { ReferralComments } from "@components/molecules/ReferralComments";
import { ReferralStatisticEntry } from "@domain/referrals/csr";
import { client } from "@integration/next-account-api";

type Props = {
  id: string;
  onAddComment: () => void;
  onClose: () => void;
};

export const ReferralCommentsContainer: FC<Props> = ({
  id,
  onAddComment,
  onClose,
}) => {
  const { data } = useQuery<{
    entry: Pick<ReferralStatisticEntry, "company" | "comments">;
  }>(
    gql`
      query ($id: String!) {
        entry: fetchReferralEntryById(id: $id) {
          id
          company
          comments {
            created
            content
          }
        }
      }
    `,
    {
      variables: { id },
      client,
    },
  );

  if (!data) {
    return <></>;
  }

  const { entry } = data;
  const commentCount = entry.comments.reduce(
    (counter, { content }) => (content !== "" ? (counter += 1) : counter),
    0,
  );

  if (commentCount === 0) {
    return <></>;
  }

  return (
    <Modal width="600px" visible onCancel={onClose}>
      <ReferralComments
        entry={entry}
        onAddComment={onAddComment}
        onClose={onClose}
      />
    </Modal>
  );
};
