import { createContext, useContext, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  UpdateEmail,
  EmailUpdateStatus,
} from "@components/organisms/UpdateEmail";
import { logError } from "@integration/errors/bugsnag/csr";
import { client } from "@integration/next-account-api";
import { ContextType } from "./types";

export const VerifyEmailContext = createContext<ContextType>({
  onVerifyEmailClick: () => null,
});
VerifyEmailContext.displayName = "VerifyEmailContext";

export const useVerifyEmailContext = () =>
  useContext<ContextType>(VerifyEmailContext);

export const VerifyEmailContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [updateEmail] = useMutation<{ success: boolean }, { email: string }>(
    gql`
      mutation ($email: String!) {
        success: updateEmail(email: $email)
      }
    `,
    { client },
  );
  const [visible, setVisible] = useState<boolean>(false);
  const [status, setStatus] = useState<EmailUpdateStatus>("enter-email");
  const onSubmit = ({ email }: { email: string }) => {
    setStatus("loading");
    updateEmail({
      variables: { email },
      onCompleted: ({ success }) => {
        setStatus(success ? "verification-email-sent" : "failed");
      },
      onError: (e) => {
        logError(e);
        setStatus("failed");
      },
    });
  };
  return (
    <VerifyEmailContext.Provider
      value={{
        onVerifyEmailClick: () => setVisible(true),
      }}
    >
      <>
        <UpdateEmail
          visible={visible}
          close={() => setVisible(false)}
          onSubmit={onSubmit}
          status={status}
          onEmailNotReceived={() => setStatus("enter-email")}
        />
        {children}
      </>
    </VerifyEmailContext.Provider>
  );
};
