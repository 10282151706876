const ONE_DAY_IN_SECONDS = 3600 * 24;

export const findCookieByName = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length !== 2) {
    return undefined;
  }
  const part = parts.pop();
  if (!part) {
    return undefined;
  }
  return part.split(";").shift();
};

export const cookieAge = ({ days }: { days: number }): number => {
  return ONE_DAY_IN_SECONDS * days;
};
