import { AccountAware } from "@components/layouts/types";
import { Footer } from "@components/molecules/Footer";
import { menuItems } from "@domain/access/menu";

type Props = AccountAware;

export const FooterContainer = ({ account }: Props) => {
  const { entries } = menuItems({
    ...account,
    accountCreatedAt: new Date(account.createdAt),
    application: account.application || undefined,
    showFeatures: true,
  });
  return <Footer menuItems={entries} />;
};
