import Link from "next/link";
import { Form, Input, Col, Row } from "antd";
import Image from "next/legacy/image";
import { Button } from "@components/atoms/Button";
import EnvelopeIcon from "@public/svg/envelope.svg";
import QuestionIcon from "@public/svg/question-mark.svg";
import { createSniperLink } from "@utils/email";
import styles from "./index.module.scss";

export type EmailUpdateStatus =
  | "enter-email"
  | "loading"
  | "failed"
  | "verification-email-sent";

export type Props = {
  close: () => void;
  onSubmit: ({ email }: { email: string }) => void;
  status: EmailUpdateStatus;
  onEmailNotReceived: () => void;
};

export const ModalContent = ({
  close,
  onSubmit,
  status,
  onEmailNotReceived,
}: Props) => {
  const [form] = Form.useForm<{ email?: string }>();
  const email = Form.useWatch("email", form);
  const isGmail = email?.includes("gmail");
  return (
    <>
      <div className={styles.close} onClick={close}>
        <Image
          src={"/svg/close.svg"}
          alt={"close image"}
          height={18}
          width={18}
        />
      </div>
      {status === "verification-email-sent" ? (
        <div className={styles.wrapper}>
          <div className={styles.envelopeWrapper}>
            <EnvelopeIcon className={styles.envelopeIcon} />
          </div>
          <div className={styles.cardWrapper}>
            <div className={styles.titleWrapper}>
              <span className={styles.cardTitle}>Ziņa nosūtīta 📬</span>
            </div>
            <div className={styles.descWrapper}>
              <span className={styles.cardDesc}>
                Dodies uz <span className={styles.strong}>{email}</span> un seko
                norādījumiem.
              </span>
            </div>

            <div className={styles.actionWrapper}>
              {email && isGmail && (
                <>
                  <div className={styles.linkWrapper}>
                    <Link href={createSniperLink({ email })} target="_blank">
                      <div className={styles.buttonWrapper}>
                        <Button type="default">Doties uz Gmail</Button>
                      </div>
                    </Link>
                  </div>
                </>
              )}

              <div onClick={onEmailNotReceived}>
                <div className={styles.quiestonWrapper}>
                  <QuestionIcon />
                  <div className={styles.emailNotReceived}>
                    Nesaņēmi e-pastu?
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.envelopeWrapper}>
            <EnvelopeIcon className={styles.envelopeIcon} />
          </div>

          <div className={styles.cardWrapper}>
            <div className={styles.titleWrapper}>
              <span className={styles.cardTitle}>Ievadi savu e-pastu</span>
            </div>
            <div className={styles.descWrapper}>
              {status === "failed" && (
                <span className={styles.cardDesc}>
                  Kaut kas nogāja greizi, ja problēma atkārtojas - raksti uz
                  info@codelex.io
                </span>
              )}
              {status === "enter-email" && (
                <span className={styles.cardDesc}>
                  Pēc e-pasta adreses apstiprināšanas tiks nosūtīts
                  apstiprinājuma e-pasts.
                </span>
              )}
            </div>

            <Form
              form={form}
              style={{ paddingTop: "15px" }}
              wrapperCol={{ span: 24 }}
              validateMessages={{
                required: "Lauks ir obligāts",
                types: {
                  email: "Izskatās, ka e-pasts nav pareizs 😿",
                },
              }}
              onFinish={({ email }) => {
                if (!email) {
                  return;
                }
                onSubmit({ email });
              }}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input
                  spellCheck={false}
                  style={{
                    backgroundColor: "#292F37",
                    color: "rgba(255, 255, 255, 0.7)",
                    height: "55px",
                    borderRadius: "10px",
                    paddingLeft: "12px",
                  }}
                  placeholder="E-pasta adrese"
                  disabled={status === "loading"}
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: "0", paddingTop: "12px" }}>
                <Row justify="center">
                  <Col style={{ width: "200px" }}>
                    <Button
                      type="default"
                      htmlType="submit"
                      loading={status === "loading"}
                    >
                      Apstiprināt
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};
