import React, { useContext, createContext } from "react";
import { signOut } from "next-auth/react";
import { useFetchAccountData } from "@domain/account/application/csr";
import { ApplicationStatus } from "@domain/applications/common";

export interface Account {
  id: string;
  email?: string;
  registrationCompleted: boolean;
  assignmentCompleted: boolean;
  anyEventJoined: boolean;
  applicationRequired: boolean;
  applied: boolean;
  personalInformationPresent: boolean;
  application?: {
    status: ApplicationStatus;
  };
  emailVerified: boolean;
}

type ContextType = {
  loading: boolean;
  account?: Account;
  refetch: () => Promise<void>;
  signOut: () => void;
  requireAccountId: () => string;
};

export const AccountContext = createContext<ContextType>({
  loading: true,
  refetch: async () => {
    return;
  },
  signOut: () => null,
  requireAccountId: () => {
    throw new Error();
  },
});

export const useAccountContext = () => useContext<ContextType>(AccountContext);

export const AccountContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { data, loading, refetch } = useFetchAccountData();
  const account: Account | undefined = data
    ? {
        ...data.account,
        applied: !!data.account.application,
        registrationCompleted:
          data.account.leadSourcePresent &&
          data.account.personalInformationPresent,
      }
    : undefined;

  return (
    <AccountContext.Provider
      value={{
        loading,
        account,
        refetch: async () => {
          await refetch();
        },
        signOut: () => {
          signOut({ callbackUrl: "/sign-in" });
        },
        requireAccountId: () => {
          if (!account) {
            throw new Error();
          }
          return account.id;
        },
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
