import { useRouter } from "next/router";
import { useEffect } from "react";
import { clientSideEnvVariable } from "src/client-side-env";

const dev = clientSideEnvVariable("DEV_ENV") === "true";
const PREFIX = "__feature_";

export const FEATURE_NAMES = [
  "intensive-course",
  "courses",
  "job-offers",
] as const;
export type FeatureName = (typeof FEATURE_NAMES)[number];

export interface Feature {
  name: FeatureName;
  enabled: boolean;
  link: string;
}

const isSkipFeatures = () => {
  return !dev || typeof window === "undefined";
};

export const fetchFeatures = (): Feature[] => {
  if (isSkipFeatures()) {
    return [];
  }
  return FEATURE_NAMES.map((it) => ({
    name: it,
    enabled: localStorage.getItem(PREFIX + it) === "true",
    link: it,
  }));
};

export const enableFeature = (name: FeatureName | string) => {
  if (isSkipFeatures()) {
    return;
  }
  localStorage.setItem(PREFIX + name, "true");
};

export const disableFeature = (name: FeatureName) => {
  if (isSkipFeatures()) {
    return;
  }
  localStorage.setItem(PREFIX + name, "false");
};

export const disableAllFeatures = () => {
  if (isSkipFeatures()) {
    return;
  }
  FEATURE_NAMES.forEach((it) => disableFeature(it));
};

export const changeFeatureState = (name: FeatureName, enabled: boolean) => {
  if (isSkipFeatures()) {
    return;
  }
  localStorage.setItem(PREFIX + name, enabled ? "true" : "false");
};

export const isFeatureEnabled = (name: FeatureName): boolean => {
  if (isSkipFeatures()) {
    return false;
  }
  return localStorage.getItem(PREFIX + name) === "true";
};

export const useFeatures = () => {
  const router = useRouter();
  useEffect(() => {
    const { query } = router;
    if (query.enabledFeatures !== typeof "string") {
      return;
    }
    const enabledFeatures = query.enabledFeatures.split("|");
    if (enabledFeatures.length > 0) {
      disableAllFeatures();
      enabledFeatures.forEach((it) => enableFeature(it));
    }
  }, [router]);
};
