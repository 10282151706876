import { StaticCourse } from "./types";

export const HIDE_CONTENT_FOR_ACCOUNTS_CREATED_AFTER = new Date("2024-05-28");

const ALL_STATIC_COURSES: StaticCourse[] = [
  {
    key: "programming-basics-with-ts",
    title: "TypeScript kurss",
    desc: "Iemācies izmantot TypeScript darba videi pietuvinātās situācijās un iegūsti praktisku pieredzi ar nozarē izmantotiem izstrādes modeļiem un labākajām praksēm.",
    navTitle: "TypeScript kurss",
    hideForAccountsCreatedAfter: HIDE_CONTENT_FOR_ACCOUNTS_CREATED_AFTER,
    withBadge: true,
  },
  {
    key: "programming-for-beginners",
    title: "TypeScript iesācējiem",
    desc: "TypeScript valodas pamati iesācējiem, sintakse, tipi un galvenās metodes.",
    navTitle: "TypeScript iesācējiem",
    withBadge: true,
  },
  {
    key: "learn-with-ai",
    title: "Mācies ar mākslīgā intelekta rīku palīdzību",
    desc: "Iepazīsties ar MI rīkiem, atklāj MI rīku potenciālu, mācies kopā ar MI un uzzini programmēšanas nākotni ar MI.",
    navTitle: "Mācies ar MI rīkiem",
    hideForAccountsCreatedAfter: HIDE_CONTENT_FOR_ACCOUNTS_CREATED_AFTER,
    withBadge: true,
  },
  {
    key: "git-and-github",
    title: "Git un GitHub pamati",
    desc: "Uzzini svarīgākās lietas par Git un GitHub.",
    navTitle: "Git un GitHub pamati",
    hideForAccountsCreatedAfter: HIDE_CONTENT_FOR_ACCOUNTS_CREATED_AFTER,
    withBadge: true,
  },
];

export const fetchAvailableStaticCourses = ({
  now,
  accountCreatedAt,
  hasAccessToProgrammingBasics,
}: {
  now: Date;
  accountCreatedAt: Date;
  hasAccessToProgrammingBasics?: boolean;
}): StaticCourse[] => {
  return ALL_STATIC_COURSES.filter((it) => {
    if (it.deprecatedAfter) {
      return now <= it.deprecatedAfter;
    }
    return true;
  }).filter((it) => {
    if (hasAccessToProgrammingBasics) {
      return true;
    }
    if (it.hideForAccountsCreatedAfter) {
      return accountCreatedAt <= it.hideForAccountsCreatedAfter;
    }
    return true;
  });
};
