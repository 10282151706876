import Image from "next/legacy/image";
import styles from "./index.module.scss";

export interface Props {
  onSignOut: () => void;
}

export const SignOutButton = ({ onSignOut }: Props) => {
  return (
    <button
      className={styles.wrapper}
      onClick={onSignOut}
      data-testid="sign-out-btn"
    >
      <div className={styles.label}>Izrakstīties</div>
      <div className={styles.icon}>
        <Image
          src={"/svg/signoutIcon.svg"}
          alt={"signout image"}
          height={20}
          width={20}
        />
      </div>
    </button>
  );
};
